<template>
  <v-form autocomplete="off" @submit.prevent="save()" ref="form">
    <v-text-field
      :label="`${$t('promotions.fields.name.description')} *`"
      :placeholder="`${$t('promotions.fields.name.title')}`"
      v-model="form.name"
      @input="delayTouch($v.form.name)"
      @blur="$v.form.name.$touch"
      :error-messages="nameErrors"
    ></v-text-field>
    <v-text-field
      :label="$t('promotions.fields.identifier.description')"
      :placeholder="$t('promotions.fields.identifier.title')"
      v-model="form.identifier"
      @input="delayTouch($v.form.identifier)"
      @blur="$v.form.identifier.$touch"
      :error-messages="identifierErrors"
      :hint="$t('promotions.fields.identifier.help')"
      clearable
      :loading="loading.identifier"
    ></v-text-field>
    <v-dialog
      ref="expiration"
      v-model="dialog.expiration"
      :return-value.sync="form.expired"
      width="290px"
      dark
      :overlay-opacity="'0.5'"
      :overlay-color="$vuetify.theme.themes.light.third"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="form.expired"
          :label="$t('promotions.fields.expiration.description')"
          :placeholder="$t('promotions.fields.expiration.title')"
          readonly
          v-bind="attrs"
          v-on="on"
          @input="delayTouch($v.form.expired)"
          @blur="$v.form.expired.$touch"
          :error-messages="expirationErrors"
          clearable
        ></v-text-field>
      </template>
      <v-date-picker
        :min="$moment().format('YYYY-MM-DD')"
        v-model="form.expired"
        scrollable
      >
        <v-spacer></v-spacer>
        <v-btn text color="third" @click="dialog.expiration = false">
          {{ $t("btn.cancel") }}
        </v-btn>
        <v-btn
          text
          color="secondary"
          @click="$refs.expiration.save(form.expired)"
        >
          {{ $t("btn.ok") }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-checkbox
      v-model="form.enabled"
      color="secondary"
      :label="$t('promotions.fields.enabled.title')"
    ></v-checkbox>
    <v-textarea
      :label="`${$t('promotions.fields.description.description')} *`"
      :placeholder="`${$t('promotions.fields.description.title')}`"
      v-model="form.description"
      @input="delayTouch($v.form.description)"
      @blur="$v.form.description.$touch"
      :error-messages="descriptionErrors"
      rows="2"
      no-resize
    ></v-textarea>
    <div class="d-flex justify-end">
      <v-btn type="submit" color="secondary" :loading="loading.save">
        <span class="black--text">
          <span v-if="promotion.id">{{ $t("btn.edit") }}</span>
          <span v-else>{{ $t("btn.add") }}</span>
        </span>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import FormDelayMixin from "./../../mixins/commons/form-delay-touch";
import Vue from "vue";
import { mapActions } from "vuex";

const defaultForm = {
  name: null,
  identifier: null,
  expired: null,
  description: null,
  enabled: true,
};

export default {
  mixins: [FormDelayMixin],
  props: {
    promotion: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  created() {
    this.initForm();
  },
  data: () => ({
    form: Object.assign({}, defaultForm),
    dialog: {
      expiration: false,
    },
    loading: {
      save: false,
      identifier: false,
    },
  }),
  validations() {
    const validations = {
      form: {
        name: {
          required,
          maxLength: maxLength(100),
        },
        identifier: {
          regex: helpers.regex("regex", /^[0-9]{3,8}$/),
          async isUnique(value) {
            if (!value) return true;
            if (value.length < 3) return true;

            if (this.promotion.identifier == value)
              return true;

            try {
              this.loading.identifier = true;
              await this.request({
                url: "api/v1/promotions/identifier/exists",
                method: "post",
                data: { identifier: this.form.identifier },
              });
              this.loading.identifier = false;
              return false;
            } catch (error) {
              this.loading.identifier = false;
              // empty
              if (error && error.response) {
                if (error.response.status === 404) return true;
              }
              return false;
            }
          },
        },
        expired: {
          date: (val) =>
            !helpers.req(val) || Vue.moment(val, "YYYY-MM-DD", true).isValid(),
        },
        description: {
          required,
          maxLength: maxLength(255),
        },
      },
    };
    return validations;
  },
  methods: {
    save() {
      let url;
      let method;
      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("errors.bad_request") });
        return;
      }

      if (this.promotion.id) {
        url = `api/v1/promotions/${this.promotion.id}`;
        method = "put";
      } else {
        url = "api/v1/promotions";
        method = "post";
      }

      this.loading.save = true;

      try {
        this.request({
          url: url,
          method: method,
          data: this.form,
          messages: {
            400: true,
            201: this.$t("promotions.add.success"),
            200: this.$t("promotions.edit.success"),
            500: true,
          },
        });

        if (!this.promotion.id) {
          this.resetForm();
        }
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    resetForm() {
      this.form = Object.assign({}, defaultForm);
      this.$v.form.$reset();
    },
    initForm() {
      if (this.promotion.id) {
        for (const prop in this.form) {
          this.form[prop] = this.promotion[prop];
        }
      }
      this.$nextTick(function() {
        this.$v.form.identifier.$touch();
      })
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  computed: {
    nameErrors() {
      const errors = [];

      if (!this.$v.form.name.$dirty) return errors;

      !this.$v.form.name.required &&
        errors.push(this.$t("promotions.fields.name.required"));
      !this.$v.form.name.maxLength &&
        errors.push(
          this.$t("promotions.fields.name.maxLength", {
            max: this.$v.form.name.$params.maxLength.max,
          })
        );

      return errors;
    },
    identifierErrors() {
      const errors = [];

      if (!this.$v.form.identifier.$dirty) return errors;

      !this.$v.form.identifier.regex &&
        errors.push(this.$t("promotions.fields.identifier.invalid"));

      (!this.$v.form.identifier.isUnique &&
        !this.loading.identifier) &&
        errors.push(this.$t("promotions.fields.identifier.already_exist"));

      return errors;
    },
    expirationErrors() {
      const errors = [];

      if (!this.$v.form.expired.$dirty) return errors;

      !this.$v.form.expired.date &&
        errors.push(this.$t("promotions.fields.expiration.invalid"));

      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.form.description.$dirty) return errors;

      !this.$v.form.description.required &&
        errors.push(this.$t("promotions.fields.description.required"));
      !this.$v.form.description.maxLength &&
        errors.push(
          this.$t("promotions.fields.description.maxLength", {
            max: this.$v.form.description.$params.maxLength.max,
          })
        );

      return errors;
    },
  },
};
</script>